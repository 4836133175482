import React from "react"

const toolbarHeight = 64;
const rootPath = `${__PATH_PREFIX__}/`

const lrSite = "https://learnraga.com"

let toolbarImage = 
  <a href={rootPath} className="gatsby-resp-image-link">
    <img 
      src={`${lrSite}/lr-logo.png`}
      alt="LeanRaga"
      style={{
        marginBottom: '0px',
        width: '150px',
        verticalAlign: 'middle',
        backgroundImage: 'none'
      }}
    />
  </a>

const Toolbar = () => {
  return <div
    style={{
      display: "flex",
      boxSizing: "border-box",
      width: "100%",
      backgroundColor: "#fff",
      color: "rgba(0,0,0,.87)",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0 0 6px 0 rgba(0,0,0,.35)",
      zIndex: 11,
      height: `${toolbarHeight}px`,
      paddingLeft: '15px',
      paddingRight: '15px'
    }}>
      {toolbarImage}
      <a href={lrSite}>Visit the Main Site</a>
  </div>;
}

export default Toolbar;