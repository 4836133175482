import React from "react"

import { rhythm } from "../utils/typography"
import Footer from "./footer"
import Toolbar from "./toolbar"

const Layout = (props) => {
  return (
    <div style={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      }}>
      <Toolbar/>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(30),
          padding: `${rhythm(1)} ${rhythm(3 / 4)}`,
          flex: "1 0 auto"
        }}
      >
        <main>
          {props.children}
        </main>
      </div>
      <Footer/>
    </div>
  )
}

export default Layout;