import React from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const aLinkStyle = {
  boxShadow: 'none',
  textShadow: 'none',
  color: '#9e9e9e',
  marginLeft: "5px",
  marginRight: "5px",
  lineHeight: "1em"
}

const lrSite = "https://learnraga.com"

const Footer = () => {
  const data = useStaticQuery(graphql`
    fragment imageShartParts on File {
      childImageSharp {
        fixed(width: 25, height: 25) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query ImageQuery {
      facebook: file(absolutePath: { regex: "/facebook.png/" }) {
        ...imageShartParts
      }
      twitter: file(absolutePath: { regex: "/twitter.png/" }) {
        ...imageShartParts
      }
      youtube: file(absolutePath: { regex: "/youtube.png/" }) {
        ...imageShartParts
      }
    }
  `);

  return <footer
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: "#e0eaef",
      width: "100%",
      fontSize: "14px",
      color: "#9e9e9e",
      flexShrink: "0",
      padding: "10px"
    }}>
    <div style = {{
      display: 'grid', gridTemplateColumns: 'auto auto auto auto',
      gridGap: '20px'}}>
      <a style={aLinkStyle} href={`${lrSite}/tos`}>Terms of Service</a>
      <a style={aLinkStyle} href={`${lrSite}/privacy-policy`}>Privacy Policy</a>
      <a style={aLinkStyle} href={`${lrSite}/cookie-policy`}>Cookie Policy</a>
      <a style={aLinkStyle} href={`${lrSite}/contact-us`}>Contact Us</a>
    </div>
    <div style = {{marginTop: "10px"}}>
      <a style={aLinkStyle} href="https://www.facebook.com/learnraga">
        <Image fixed={data.facebook.childImageSharp.fixed}></Image>
      </a>
      <a style={aLinkStyle} href="https://twitter.com/learnraga">
        <Image fixed={data.twitter.childImageSharp.fixed}></Image>
      </a>
      <a style={aLinkStyle} href="https://www.youtube.com/learnraga">
        <Image fixed={data.youtube.childImageSharp.fixed}></Image>
      </a>
    </div>
    <div>
      <span style={{verticalAlign: 'middle'}}>© </span>{new Date().getFullYear()} Paya Labs, Inc.
    </div>
  </footer>
}

export default Footer;