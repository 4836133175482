import Typography from "typography"
import TypographyTheme from "typography-theme-twin-peaks"

let linkColor = "#007ACC"

TypographyTheme.overrideThemeStyles = () => ({
  "p": {
    marginTop: "1rem",
    marginBottom: "1rem",
    lineHeight: 1.68
  },
  "a": {
    color: linkColor,
    textDecoration: "none",
    backgroundImage: "none",
  },
  "main a:hover, main a:active": {
    textShadow:
    ".03em 0 #fff,-.03em 0 #fff,0 .03em #fff,0 -.03em #fff,.06em 0 #fff,-.06em 0 #fff,.09em 0 #fff,-.09em 0 #fff,.12em 0 #fff,-.12em 0 #fff,.15em 0 #fff,-.15em 0 #fff", // eslint-disable-line
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1px, ${linkColor} 1px, ${linkColor} 2px, rgba(0, 0, 0, 0) 2px)`, // eslint-disable-line
  },
  "h1": {
    fontSize: "40px"
  },
  "h2": {
    fontSize: "35px"
  },
  "h3": {
    fontSize: "28px",
    color: "#5d5959"
  },
  ".caption": {
    marginTop: "-25px"
  }
})

TypographyTheme.googleFonts = [
  {
    name: "Roboto Slab",
    styles: ["700"],
  },
  {
    name: "Roboto",
    styles: ["400", "400i", "600"],
  },
]

TypographyTheme.baseFontSize = "19px";
TypographyTheme.scaleRatio = 2;

TypographyTheme.headerFontFamily = ["Roboto Slab", "sans-serif"];
TypographyTheme.bodyFontFamily = ["Roboto", "serif"];

//delete TypographyTheme.googleFonts

const typography = new Typography(TypographyTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
